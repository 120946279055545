import React from 'react';

export const breadCrumbData = {
  title: 'Permissions',
  bread_crumbs: [
    {
      class: 'breadcrumb-item',
      label: 'Dashboard',
      path: '/admin/dashboard',
    },
    {
      class: 'breadcrumb-item active',
      label: <React.Fragment>Permissions</React.Fragment>,
    },
  ],
};
