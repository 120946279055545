import React from 'react';
import Select from 'react-select';
import './style.css';
import { ErrorMessage, useFormikContext } from 'formik';

const SelectBox = ({ field, label, placeholder, options, isClearable, ...rest }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className='admin-react-select-box'>
      <label className='text-white'>
        {label} <span className='text-danger'>*</span>
      </label>
      <Select
        {...field}
        {...rest}
        name={field.name}
        placeholder={placeholder}
        options={options}
        onChange={(value) => {
          setFieldValue(field.name, value);
        }}
        styles={{
          option: (styles, { isSelected, isDisabled }) => ({
            ...styles,
            backgroundColor: isSelected ? '#10182d' : '#1B243A',
            color: isSelected ? '#fff' : '#fff',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            marginTop: '0px',
            marginBottom: '0px',
          }),
        }}
        isClearable={isClearable ? isClearable : false}
      />
      <ErrorMessage name={field.name} component='span' className='text-danger' />
    </div>
  );
};

export default SelectBox;
