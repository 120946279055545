/* eslint-disable no-unused-vars */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { LuLogOut } from 'react-icons/lu';
import { FaUsers } from 'react-icons/fa';
import './style.css';
import { useGetPermissions } from '../../Hooks/useGeneralHook';

const AdminSidebar = ({ setOpenSidebar }) => {
  const { permission_check } = useGetPermissions();

  return (
    <div
      className='sidebar'
      id='sidebar'
      style={setOpenSidebar === true ? { marginLeft: '0px' } : { marginLeft: '-225px' }}
    >
      <div className='sidebar-inner slimscroll'>
        <div id='sidebar-menu' className='sidebar-menu'>
          <ul>
            <li>
              <NavLink to='/admin/dashboard' className='text-nowrap d-flex'>
                <i data-feather='home' />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='feather feather-home'
                >
                  <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                  <polyline points='9 22 9 12 15 12 15 22'></polyline>
                </svg>
                <span>Dashboard</span>
              </NavLink>
            </li>
            {permission_check('permission-list') && (
              <li>
                <NavLink to='/admin/permissions' className='text-nowrap d-flex'>
                  <i data-feather='permission' />
                  <FaUsers className='icon-width-height' />
                  <span>Permission</span>
                </NavLink>
              </li>
            )}
            {permission_check('role-list') && (
              <li>
                <NavLink to='/admin/roles' className='text-nowrap d-flex'>
                  <i data-feather='role' />
                  <FaUsers className='icon-width-height' />
                  <span>Role</span>
                </NavLink>
              </li>
            )}
            {permission_check('user-list') && (
              <li>
                <NavLink to='/admin/users' className='text-nowrap d-flex'>
                  <i data-feather='user' />
                  <FaUsers className='icon-width-height' />
                  <span>User</span>
                </NavLink>
              </li>
            )}
            {permission_check('customer-returns') && (
              <li>
                <NavLink to='/admin/customer-returns' className='text-nowrap d-flex'>
                  <i data-feather='user' />
                  <FaUsers className='icon-width-height' />
                  <span>Customer Returns</span>
                </NavLink>
              </li>
            )}

            <li>
              <NavLink to='/admin/logout' className='text-nowrap d-flex'>
                <i data-feather='user' />
                <LuLogOut className='icon-width-height' />
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AdminSidebar;
