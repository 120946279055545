import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useDeleteRole, useGetRolePagination } from '../../hooks/useRoleManagement';
import HelmetComponent from '../../../../Components/HelmetComponent';
import BreadCrumb from '../../../../Components/BreadCrumb';
import { useBreadCrumbData } from './hooks/useRoleList';
import { useGetPermissions } from '../../../../Hooks/useGeneralHook';

const RoleList = () => {
  const navigate = useNavigate();
  const { permission_check } = useGetPermissions();
  const { breadCrumbData } = useBreadCrumbData();
  const { roleList, isLoadingRoleList, refetchRoleList } = useGetRolePagination();
  const { handleDeleteRole } = useDeleteRole(refetchRoleList);

  return (
    <React.Fragment>
      <HelmetComponent title='Admin Role Management' />
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <BreadCrumb data={breadCrumbData} />
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <table className='table table-center table-manager'>
                      <thead className='thead-light'>
                        <tr>
                          <th>Role Name</th>
                          <th>Permission</th>
                          <th className='text-center'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingRoleList === false && roleList ? (
                          <React.Fragment>
                            {roleList.map((role, index) => (
                              <tr key={index}>
                                <td>{role?.display_name || '-'}</td>
                                <td className='text-wrap'>
                                  {role?.permissions.map((role, index) => (
                                    <span key={`role-list-permission-index-${index}`}>
                                      {index + 1}. {role.permissions?.display_name || ''}&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                  ))}
                                </td>
                                <td className='text-center'>
                                  {permission_check('role-edit') && (
                                    <Tooltip
                                      title='Edit'
                                      className='btn btn-sm btn-white text-success me-2'
                                      onClick={() =>
                                        navigate('/admin/edit-role-permission', {
                                          state: { id: role?._id },
                                        })
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon className='text-secondary' />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {permission_check('role-delete') && (
                                    <Tooltip
                                      title='Delete'
                                      className='btn btn-sm btn-white text-danger me-2'
                                      onClick={() => handleDeleteRole(role?._id)}
                                    >
                                      <IconButton>
                                        <DeleteIcon className='text-danger' />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {roleList.length === 0 && (
                              <tr>
                                <td align='left' colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ) : (
                          <tr>
                            <td align='left' colSpan={8}>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RoleList;
