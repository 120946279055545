import * as Yup from 'yup';

export const LoginInitialValues = {
  email: '',
  password: '',
};

export const LoginValidationSchema = Yup.object()
  .shape({
    email: Yup.string().email('Invalid email').required('Please enter email'),
    password: Yup.string().required('Please enter your password.').min(8, 'Your password is too short.'),
  })
  .defined();
