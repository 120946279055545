import * as Yup from 'yup';

export const CustomerReturnsInitialValues = {
  return_id: '',
};

export const CustomerReturnsValidationSchema = Yup.object().shape({
  return_id: Yup.string().required('Please enter return id'),
});

export const CustomerReturnAPIResponse = {
  ReceiptId: 'RA465',
  BizId: 'ASHWORTH',
  BizPurchaseOrder: null,
  Status: '00',
  OrderType: 'S01',
  OrderClass: 'ECOMRTN',
  SupplierId: '617530',
  BizSupplierId: null,
  SupplierGroup: null,
  SupplierName: 'Steve Grant',
  ShippingAddressId: 'SHPADD',
  InvoiceAddressId: null,
  Site: '0001',
  Warehouse: 'WAREHOUSE1',
  OwnerId: 'ASHWORTH',
  StockStatus: '00',
  PurchaseOrder: 'AW#13668',
  Prime: 'RA465',
  Priority: '03',
  SupplierOrder: null,
  SupplierRef: 'AW#13668',
  ConsignmentId: 'RA465',
  ASNumber: null,
  GRNNumber: null,
  ManifestNumber: null,
  BOL: null,
  MasterBOL: null,
  POC: null,
  ShippingMethod: null,
  Region: null,
  DC: null,
  Store: null,
  CarrierId: null,
  CarrierScac: null,
  CarrierTrackingNumber: null,
  CarrierTrackingNumberURL: null,
  Route: null,
  LoadId: null,
  LoadSequence: 0.0,
  ReceiptLane: 'RTN01',
  ReturnReason: null,
  QA: null,
  Lines: 1.0,
  LineQty: 1.0,
  StUQty: 1.0,
  Volume: 0.0,
  Weight: 0.0,
  ActualWeight: 0.0,
  TaskCountNew: 0.0,
  TaskCountCurrent: 0.0,
  TaskCountActioned: 2.0,
  TimeToUnload: 0.0,
  TimeToInspect: 209.0,
  TimeToPutaway: 248.0,
  TimeOther: 0.0,
  TimeToCollect: 0.0,
  AccepanceCode: null,
  Variance: '0',
  Supervisor: null,
  Reason: null,
  DateCreated: '2024-04-17T14:02:41',
  DateSuspended: null,
  DateClosed: '2024-04-24T12:03:53',
  DateCollected: null,
  DateDueIn: '2024-04-17T14:02:41',
  DateArrival: '2024-04-23T11:49:51',
  DateReceipt: '2024-04-12T07:00:00',
  ASNInd: null,
  PaperworkInd: '1',
  OverdueInd: '0',
  Stage: '90',
  MaintInd: 'H',
  ReceiptLines: [
    {
      ReceiptId: 'RA465',
      Values: null,
      Line: 'Q60WMH55N6',
      Level: '00',
      SKUId: 'AM2001-311-XL',
      BizSKUId: null,
      SupplierSKUId: null,
      UPC: null,
      UPCDescription: null,
      Category: null,
      UnitOfMeasure: 'EA',
      LineOwner: null,
      LineStockStatus: null,
      QtyOrdered: 1.0,
      QtyAdvised: 1.0,
      QtyReceived: 1.0,
      QtyRejected: 0.0,
      QtyDueIn: 0.0,
      POLineId: 1.0,
      ReturnReason: null,
      QA: null,
      Acceptance: null,
      Variance: '0',
      BOInd: '0',
      ConsignmentId: 'RA465',
      SiteId: '0001',
      Warehouse: 'WAREHOUSE1',
      BizId: 'ASHWORTH',
      OwnerId: 'ASHWORTH',
      StockStatus: '00',
      DateReceipt: '2024-04-12T07:00:00',
      AttachmentInd: '0',
      SpecialConditionInd: '0',
      Stage: '90',
      productalternates: [
        {
          AlternateSKUId: '810065060051',
          LineId: '1',
          OriginalSKUId: 'AM1001-200-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099659733',
          LineId: '1',
          OriginalSKUId: 'AM2012-221-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099657562',
          LineId: '1',
          OriginalSKUId: 'AM2054-307-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099652758',
          LineId: '1',
          OriginalSKUId: 'AM5004-215-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653427',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-35-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065062642',
          LineId: '1',
          OriginalSKUId: 'KDL00119-909-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655636',
          LineId: '1',
          OriginalSKUId: 'AM1011-957-XXXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655711',
          LineId: '1',
          OriginalSKUId: 'AM1011-961-M',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655742',
          LineId: '1',
          OriginalSKUId: 'AM1011-961-XXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655759',
          LineId: '1',
          OriginalSKUId: 'AM1011-961-XXXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650136',
          LineId: '1',
          OriginalSKUId: 'AM1002-930-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655766',
          LineId: '1',
          OriginalSKUId: 'AM1011-963-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655797',
          LineId: '1',
          OriginalSKUId: 'AM1011-963-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655810',
          LineId: '1',
          OriginalSKUId: 'AM1011-963-XXXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658781',
          LineId: '1',
          OriginalSKUId: 'AM1011-965-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650143',
          LineId: '1',
          OriginalSKUId: 'AM1002-930-XXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099651317',
          LineId: '1',
          OriginalSKUId: 'AM1028-931-M',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655056',
          LineId: '1',
          OriginalSKUId: 'AM1064-962-M',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655162',
          LineId: '1',
          OriginalSKUId: 'AM1065-204-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655179',
          LineId: '1',
          OriginalSKUId: 'AM1065-204-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655186',
          LineId: '1',
          OriginalSKUId: 'AM1065-204-XXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650310',
          LineId: '1',
          OriginalSKUId: 'AM1005-215-M',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655124',
          LineId: '1',
          OriginalSKUId: 'AM1065-215-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655131',
          LineId: '1',
          OriginalSKUId: 'AM1065-215-XXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '840344401817',
          LineId: '1',
          OriginalSKUId: 'AM1092-215-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650471',
          LineId: '1',
          OriginalSKUId: 'AM1007-100-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653274',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-36-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653434',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-36-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650624',
          LineId: '1',
          OriginalSKUId: 'AM1011-946-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655667',
          LineId: 'Q7M9YP9YQZ',
          OriginalSKUId: 'AM1011-946-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653373',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-38-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653441',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-38-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653298',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-40-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653397',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-42-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650631',
          LineId: '1',
          OriginalSKUId: 'AM1011-946-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655674',
          LineId: 'Q7M9WQAEA0',
          OriginalSKUId: 'AM1011-946-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069429',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-32-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069450',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-33-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069474',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-34-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069481',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-34-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069511',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-35-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650648',
          LineId: '1',
          OriginalSKUId: 'AM1011-946-XXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069542',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-36-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069566',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-38-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069603',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-40-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069627',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-42-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069634',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-42-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655698',
          LineId: '1',
          OriginalSKUId: 'AM1011-946-XXXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069641',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-42-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069689',
          LineId: '1',
          OriginalSKUId: 'AM5008-300-32-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069726',
          LineId: '1',
          OriginalSKUId: 'AM5008-300-33-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069764',
          LineId: '1',
          OriginalSKUId: 'AM5008-300-34-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653366',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-36-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653281',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-38-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653380',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-40-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653458',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-40-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653304',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-42-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653311',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-44-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099653403',
          LineId: '1',
          OriginalSKUId: 'AM5006-311-44-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069412',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-32-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069467',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-33-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069498',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-34-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069528',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-35-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069535',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-36-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069559',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-36-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069573',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-38-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069580',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-38-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069597',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-40-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069610',
          LineId: '1',
          OriginalSKUId: 'AM5008-211-40-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069696',
          LineId: '1',
          OriginalSKUId: 'AM5008-300-32-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069740',
          LineId: '1',
          OriginalSKUId: 'AM5008-300-34-29',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069757',
          LineId: '1',
          OriginalSKUId: 'AM5008-300-34-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069788',
          LineId: '1',
          OriginalSKUId: 'AM5008-300-35-31',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065069795',
          LineId: '1',
          OriginalSKUId: 'AM5008-300-35-33',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658804',
          LineId: '1',
          OriginalSKUId: 'AM1011-965-XXXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099657906',
          LineId: '1',
          OriginalSKUId: 'AM1066-949-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '840344402098',
          LineId: '1',
          OriginalSKUId: 'AM1102-913-XXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658811',
          LineId: '1',
          OriginalSKUId: 'AM1011-969-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658842',
          LineId: '1',
          OriginalSKUId: 'AM1011-969-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658859',
          LineId: '1',
          OriginalSKUId: 'AM1011-969-XXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658866',
          LineId: '1',
          OriginalSKUId: 'AM1011-969-XXXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658941',
          LineId: '1',
          OriginalSKUId: 'AM1011-971-M',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065060075',
          LineId: '1',
          OriginalSKUId: 'AM1001-200-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650150',
          LineId: '1',
          OriginalSKUId: 'AM1002-931-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658965',
          LineId: '1',
          OriginalSKUId: 'AM1011-971-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099658989',
          LineId: '1',
          OriginalSKUId: 'AM1011-971-XXXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065060853',
          LineId: '1',
          OriginalSKUId: 'AM1014-301-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065060884',
          LineId: '1',
          OriginalSKUId: 'AM1014-301-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810065060891',
          LineId: '1',
          OriginalSKUId: 'AM1014-301-XXL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650655',
          LineId: '1',
          OriginalSKUId: 'AM1019-100-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650679',
          LineId: '1',
          OriginalSKUId: 'AM1019-100-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650754',
          LineId: '1',
          OriginalSKUId: 'AM1019-215-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650785',
          LineId: '1',
          OriginalSKUId: 'AM1019-215-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650174',
          LineId: '1',
          OriginalSKUId: 'AM1002-931-L',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650730',
          LineId: '1',
          OriginalSKUId: 'AM1019-300-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650808',
          LineId: '1',
          OriginalSKUId: 'AM1019-408-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650839',
          LineId: '1',
          OriginalSKUId: 'AM1019-408-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650853',
          LineId: '1',
          OriginalSKUId: 'AM1019-602-S',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099650884',
          LineId: '1',
          OriginalSKUId: 'AM1019-602-XL',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
        {
          AlternateSKUId: '810099655896',
          LineId: '1',
          OriginalSKUId: 'AM1020-110-M',
          Type: '10',
          Reference: null,
          MaintInd: 'W',
        },
      ],
    },
  ],
  ReceiptAddresses: [],
};
