import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Pagination } from '../../../../Utils';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import format from 'date-fns/format';
// import PrintIcon from '@mui/icons-material/Print';
import { useFetchCustomerReturns } from '../../hooks/useCustomerReturnsManagement';
import HelmetComponent from '../../../../Components/HelmetComponent';
import BreadCrumb from '../../../../Components/BreadCrumb';
import { breadCrumbData } from './constant';
import FetchReturns from '../FetchReturns';
// import { format } from 'date-fns';
// import BarcodeComponent from '../../../../Components/BarcodeComponent';
// import ReactToPrint from 'react-to-print';
// import { IconButton } from '@mui/material';
import Row from './components/Row';

const CustomerReturnsList = () => {
  // const navigate = useNavigate();
  // const printComponentRef = useRef([]);
  const { handleSubmitFetchCustomerReturnsForm, isLoading, returnResponse } = useFetchCustomerReturns();
  const isLoadingUserList = isLoading;

  return (
    <React.Fragment>
      <HelmetComponent title='Customer Returns' />
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <BreadCrumb data={breadCrumbData} />
          <FetchReturns
            handleSubmitFetchCustomerReturnsForm={handleSubmitFetchCustomerReturnsForm}
            isLoading={isLoading}
          />
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive custom-pagination'>
                    <table className='table table-center datatable table-manager'>
                      <thead className='thead-light'>
                        <tr>
                          {/* <th /> */}
                          <th>Receipt ID</th>
                          {/* <th>SKU ID</th> */}
                          <th>Unit Of Measure</th>
                          <th>QTY</th>
                          <th>Warehouse</th>
                          <th>Owner ID</th>
                          <th>Date Receipt</th>
                          <th>Stage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingUserList ? (
                          <tr>
                            <td align='center' colSpan={7} style={{fontSize: "60px",height: "150px", width: "150px" }}>
                              Loading... <i className='fas fa-spinner fa-spin'></i>
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment>
                            {returnResponse?.ReceiptLines?.length > 0 ? (
                              <React.Fragment>
                                {/* {returnResponse?.ReceiptLines.map((receipt_line, index) => (
                                  <Row index={index} receipt_line={receipt_line} aletrnates={returnResponse.aletrnates} key={`customer-receipt-line-index-${index}`} />
                                ))} */}
                                {returnResponse?.oneItem && <>
                                  <Row index={0} receipt_line={returnResponse?.oneItem} ReceiptLines={returnResponse?.ReceiptLines} key={`customer-receipt-line-index-${0}`} />
                                </>}
                              </React.Fragment>
                            ) : (
                              <tr>
                                <td colSpan={7}>No Records Found.</td>
                              </tr>
                            )}
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerReturnsList;
