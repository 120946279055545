// import { useMutation } from '@tanstack/react-query';
// import { ErrorToast } from '../../../Utils/SweetAlert';
import { GetCustomerReturns } from '../../../Api/customerReturns';
import { useState } from 'react';
// import { CustomerReturnAPIResponse } from '../constant';

export const useFetchCustomerReturns = () => {
  const [returnResponse, setReturnResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const fetchCustomerReturnsMutate = useMutation({
  //   mutationFn: (data) => GetCustomerReturns(data),
  // });
  // const fetchCustomerReturnProductAlternateMutate = useMutation({
  //   mutationFn: (data) => GetReturnProductAlternates(data),
  // });
  const handleSubmitFetchCustomerReturnsForm = (evt) => {
    console.log("evt", evt);
    // fetchCustomerReturnsMutate.mutate(values.return_id, {
    //   onSuccess: async (res) => {
    //     if (res?.ReceiptLines && res?.ReceiptLines.length > 0) {
    //       const patternsMutations = res?.ReceiptLines.map((receipt) => {
    //         return {
    //           ...receipt,
    //           productalternates: fetchCustomerReturnProductAlternateMutate.mutateAsync(receipt?.SKUId)
    //         }
    //       });
    //       // waiting for all patterns to be created under a particular puzzle
    //       return Promise.allSettled(patternsMutations);
    //     }
    //     setReturnResponse(res);
    //   },
    //   onError: (err) => {
    //     if (typeof err.response.data.message !== 'undefined') {
    //       ErrorToast(err.response.data.message || 'Server Error!!');
    //     } else {
    //       ErrorToast(err?.message || 'Server Error!!');
    //     }
    //   },
    // });
    /* const { isFetching: isLoadingPermission, data: permissionArr } = useQuery({
      queryKey: ['get-user-login-permissions'],
      queryFn: () => adminGetUserPermissionService(),
      select: (res) => res?.data?.data?.permissions || [],
    }); */
    setIsLoading(true);
    GetCustomerReturns(evt.return_id)
    .then((resp)=>{
      setIsLoading(false);
      console.log("handleSubmitFetchCustomerReturnsForm data", resp?.data?.data);
      setReturnResponse(resp.data?.data);
    })
    .catch((error)=>{
      setIsLoading(false);
      console.log("handleSubmitFetchCustomerReturnsForm error", error);
    })
  };

  return { handleSubmitFetchCustomerReturnsForm, isLoading, returnResponse };
};
