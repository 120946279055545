import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminRoutesPermission from './AdminRoutesPermission';
import AuthRoutes from './AuthRoutes';
import { useRefetchUserPermission } from '../Hooks/useGeneralHook';
import { AppContext } from '../context/appContext';

export default function Routings() {
  const { permissionArr, isLoadingPermission } = useRefetchUserPermission();

  return (
    <React.Fragment>
      <AppContext.Provider
        value={{
          permissionArr,
        }}
      >
        <Routes>
          {!isLoadingPermission && <Route path='/admin/*' element={<AdminRoutesPermission />} />}
          <Route path='/*' element={<AuthRoutes />} />
        </Routes>
      </AppContext.Provider>
    </React.Fragment>
  );
}
