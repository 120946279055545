import React, { useRef, useEffect, useState } from 'react'; //useEffect, 
import PrintIcon from '@mui/icons-material/Print';
import { format } from 'date-fns';
import { Collapse, IconButton } from '@mui/material';
import BarcodeComponent from '../../../../../../Components/BarcodeComponent';
import { useProductAlternatesManagement } from '../../../../hooks/useProductAlternatesManagement';

const Row = ({ index, receipt_line, ReceiptLines }) => {
  const [alertnate, setAlertnate] = useState([]);
  const printComponentRef = useRef([]);
  console.log("index, receipt_line", index, receipt_line);
  const { handleSubmitFetchAlternateData, isLoading, returnResponse } = useProductAlternatesManagement();

  const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
  useEffect(()=>{
    const fetchData = async () => {
      if(ReceiptLines?.length){
        for (const itm of ReceiptLines) {
          handleSubmitFetchAlternateData({ sku_id: itm?.SKUId });
          await delay(1000);
        }
      }
    };
    fetchData();
  }, []);
  
  useEffect(()=>{
    if(returnResponse?.length){
      setAlertnate([...alertnate, ...returnResponse]);
    }
  }, [returnResponse]);

  /* const onClickOpen = async () => {
    setOpen(!open);
    if(!open){
      handleSubmitFetchAlternateData({ sku_id: receipt_line?.SKUId });
    }
  }; */
  const onPrint = async (innerContents) => {
    const popupWindow = window.open('');

    popupWindow.document.write('<!DOCTYPE html><html><head><style type="text/css">@media print { body { -webkit-print-color-adjust: exact; } }</style></head><body> ' + innerContents + '</body></html>');
    setTimeout(function() {
        popupWindow.focus();
        popupWindow.print();
        popupWindow.close();
    }, 100);
  };
  /* useEffect(()=>{
    if(index == 0){
      handleSubmitFetchAlternateData({ sku_id: receipt_line?.SKUId });
    }
  }, [index]); */
  return (
    <React.Fragment>
      <tr>
        <td>{receipt_line?.ReceiptId}</td>
        <td>{receipt_line?.UnitOfMeasure}</td>
        <td>{receipt_line?.QtyOrdered}</td>
        <td>{receipt_line?.Warehouse}</td>
        <td>{receipt_line?.OwnerId}</td>
        <td>{format(new Date(receipt_line?.DateReceipt), 'dd MMMM, yyyy')}</td>
        <td>{receipt_line?.OwnerId}</td>
      </tr>
      {/* {isLoading && (<tr>
        <td align='center' colSpan={9} style={{fontSize: "60px",height: "150px", width: "150px" }}>
          Loading... <i className='fas fa-spinner fa-spin'></i>
        </td>
      </tr>)} */}{/* !isLoading &&  */}
      {alertnate && alertnate.length > 0 && (
        <tr>
          <td colSpan={9}>
            <Collapse timeout={1000} in={index == 0}>
              <table className='table mb-0' style={{ backgroundColor: '#4f4f4f' }}>
                <thead className='thead-light'>
                  <tr>
                    <th>SKU ID</th>
                    <th>UPC/Alternate SKU ID</th>
                    <th>Barcode</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {alertnate.map((alternate, index) => (
                    <tr key={`product-alternate-key-${alternate?.AlternateSKUId}`}>
                      <td>{alternate?.OriginalSKUId || '-'}</td>
                      <td>{alternate?.AlternateSKUId || '-'}</td>
                      <td>
                        <BarcodeComponent
                          ref={(el) => (printComponentRef.current[index] = el)}
                          data={alternate?.AlternateSKUId}
                        />
                      </td>
                      <td>
                        <IconButton className='text-white' onClick={()=>onPrint(printComponentRef.current[index]?.innerHTML)}>
                          <PrintIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Collapse>
          </td>
        </tr>
      )}
    {console.log("isLoading", isLoading)}
      {isLoading && (<tr>
        <td align='center' colSpan={9} style={{fontSize: "60px",height: "150px", width: "150px" }}>
          Loading... <i className='fas fa-spinner fa-spin'></i>
        </td>
      </tr>)}
    </React.Fragment>
  );
};

export default Row;
