/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const AdminHeader = (props) => {
  return (
    <div>
      <div className='header-left header-content header-left-one'>
        <Link to='/admin/dashboard' className='logo'>
          {/* <img src='/images/logo.png' className='header-logo' alt='user.png' /> */}
          <h4 className='text-white text-nowrap'>Newtimes Fulfillment</h4>
        </Link>
        <Link to='/admin/dashboard' className='logo logo-small'>
          {/* <img src='/images/small_logo.svg' className='header-small-logo' alt='user.png' /> */}
          <h3 className='text-white'>NF</h3>
        </Link>
      </div>
      <Link to='#' id='toggle_btn' className='header-toggle-btn'>
        <i className='fas fa-bars'></i>
      </Link>
      <Link to='#' className='mobile_btn' id='mobile_btn' onClick={() => props.onClick(!props.showSideBar)}>
        <i className='fas fa-bars'></i>
      </Link>
    </div>
  );
};

export default AdminHeader;
