import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { SuccessToast, ErrorToast } from '../../../Utils/SweetAlert';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordService } from '../../../Api/auth';
import { Helmet } from 'react-helmet-async';
import { ResetPasswordInitialValues, ResetPasswordValidationSchema } from './constant';
import './style.css';
import PassowrdField from '../../../Components/PasswordField';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Admin Reset Password</title>
      </Helmet>
      <div>
        <div className='main-wrapper login-body'>
          <div className='login-wrapper'>
            <div className='container'>
              <h1 className='img-fluid text-center text-white'>{process.env.REACT_APP_NAME} Admin</h1>
              <div className='loginbox'>
                <div className='login-right'>
                  <div className='login-right-wrap'>
                    <h1 className='forgot-title'>Reset Password</h1>
                    <p className='forgot-subtitle'>Enter New Password</p>
                    <Formik
                      initialValues={ResetPasswordInitialValues}
                      validationSchema={ResetPasswordValidationSchema}
                      validateOnChange
                      onSubmit={async (values, { setSubmitting }) => {
                        const resetPasswordObj = {
                          forgotten_password_code: token,
                          new_password: values.password,
                          confirm_new_password: values.confirm_password,
                        };
                        await ResetPasswordService(resetPasswordObj)
                          .then((res) => {
                            SuccessToast(res?.data?.message || 'Password has been reset successfully.');
                            setSubmitting(false);
                            navigate('/');
                          })
                          .catch((err) => {
                            if (typeof err.response.data.message !== 'undefined') {
                              ErrorToast(err.response.data.message || 'Server Error!!');
                            } else {
                              ErrorToast(err?.message || 'Server Error!!');
                            }
                          });
                      }}
                    >
                      {({ isSubmitting }) => {
                        return (
                          <Form>
                            <div className='form-group m-bottom'>
                              <label className='text-white'>
                                Password<span className='text-danger'>*</span>
                              </label>
                              <Field component={PassowrdField} name='password' placeholder='Enter password' />
                              <ErrorMessage name='password' component='span' className='error' />
                            </div>
                            <div className='form-group'>
                              <label className='text-white'>
                                Confirm Password<span className='text-danger'>*</span>
                              </label>
                              <Field component={PassowrdField} name='confirm_password' placeholder='Enter password' />
                              <ErrorMessage name='confirm_password' component='span' className='error' />
                            </div>
                            <div className='form-group mt-4'>
                              <button
                                className='reset-submit-btn btn btn-lg btn-block btn-primary w-100 rset'
                                type='submit'
                                disabled={isSubmitting}
                              >
                                Reset Password&nbsp;&nbsp;
                                {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                    <div className='text-center remember-text-class-link'>
                      Remember your password? <NavLink to='/'>Login</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
