import { useEffect, useState } from 'react';
import { ErrorToast, SuccessToast } from '../../../Utils/SweetAlert';
import { AddPermissionService, GetPermissionPaginate } from '../../../Api/permission';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetPermissions = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const {
    isFetching: isLoadingPermissions,
    data: permissionList,
    refetch: refetchPermission,
  } = useQuery({
    queryKey: ['get-permission-list-pagination'],
    queryFn: () =>
      GetPermissionPaginate({
        limit,
        page,
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    refetchPermission();
  }, [limit, page]);

  return { isLoadingPermissions, permissionList, handlePageChange, handleLimitChange, limit, page, refetchPermission };
};

export const useCreatePermission = (refetchPermission) => {
  const permissionCreateMutate = useMutation({
    mutationFn: (data) => AddPermissionService(data),
  });
  const handleSubmitCreatePermissionForm = (values) => {
    const postData = {
      name: values.name,
    };
    permissionCreateMutate.mutate(postData, {
      onSuccess: (res) => {
        SuccessToast(res?.data?.message || 'Permission created successfully.');
        refetchPermission();
      },
      onError: (err) => {
        if (typeof err.response.data.message !== 'undefined') {
          ErrorToast(err.response.data.message || 'Server Error!!');
        } else {
          ErrorToast(err?.message || 'Server Error!!');
        }
      },
    });
  };

  return { handleSubmitCreatePermissionForm, isLoading: permissionCreateMutate.isLoading };
};
