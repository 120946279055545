import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ Component, updateHeaderFun }) => {
  const loginUser = localStorage.getItem('accessToken');
  let isLogin = false;
  if (loginUser !== null) {
    isLogin = true;
  }
  return isLogin ? (
    <Component updateHeaderFun={typeof updateHeaderFun !== 'undefined' ? updateHeaderFun : ''} />
  ) : (
    <Navigate to='/' />
  );
};

export default PrivateRoute;
