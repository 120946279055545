import { useMutation, useQuery } from '@tanstack/react-query';
import {
  AddRoleService,
  DeleteRoleById,
  EditRoleService,
  GetPermissionsService,
  GetRoleListById,
  GetRoleListService,
} from '../../../Api/role';
import { Confirmation, ErrorToast, SuccessToast } from '../../../Utils/SweetAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const useGetPermission = () => {
  const { data: permissions, isFetching: isLoadingPermissionList } = useQuery({
    queryKey: ['get-permissions-list-add-role'],
    queryFn: () => GetPermissionsService(),
    select: (res) => {
      const modifiedRoleArr = res?.data?.data.map((value) => {
        return {
          label: value.display_name,
          value: value._id,
        };
      });
      return modifiedRoleArr;
    },
    throwOnError: (err) => {
      if (typeof err.response.data.message !== 'undefined') {
        ErrorToast(err.response.data.message || 'Server Error!!');
      } else {
        ErrorToast(err?.message || 'Server Error!!');
      }
    },
  });
  return { permissions, isLoadingPermissionList };
};

export const useGetRolePagination = () => {
  const {
    data: roleList,
    isFetching: isLoadingRoleList,
    refetch: refetchRoleList,
  } = useQuery({
    queryKey: ['get-role-list'],
    queryFn: () => GetRoleListService(),
    select: (res) => res?.data?.data || [],
    throwOnError: (err) => {
      if (typeof err.response.data.message !== 'undefined') {
        ErrorToast(err.response.data.message || 'Server Error!!');
      } else {
        ErrorToast(err?.message || 'Server Error!!');
      }
    },
  });
  return { roleList, isLoadingRoleList, refetchRoleList };
};

export const useDeleteRole = (refetchRoleList) => {
  const handleDeleteRoleHook = useMutation({
    mutationFn: (data) => DeleteRoleById(data),
  });

  const handleDeleteRole = (deleteRoleId) => {
    Confirmation('Want to delete role?').then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        handleDeleteRoleHook.mutate(deleteRoleId, {
          onSuccess: (res) => {
            SuccessToast(res?.data?.message || 'Role Deleted.');
            refetchRoleList();
          },
          onError: (err) => {
            if (typeof err.response.data.message !== 'undefined') {
              ErrorToast(err.response.data.message || 'Server Error!!');
            } else {
              ErrorToast(err?.message || 'Server Error!!');
            }
          },
        });
      }
    });
  };

  return { handleDeleteRole };
};

export const useGetSingleRole = (formRef) => {
  const stateData = useLocation();
  const { id } = stateData?.state || {};

  const {
    data: roleData,
    isFetching: isLoadingRole,
    refetch: refetchRoleList,
  } = useQuery({
    queryKey: ['get-single-role'],
    enabled: !!id,
    queryFn: () => GetRoleListById(id),
    throwOnError: (err) => {
      if (typeof err.response.data.message !== 'undefined') {
        ErrorToast(err.response.data.message || 'Server Error!!');
      } else {
        ErrorToast(err?.message || 'Server Error!!');
      }
    },
  });

  useEffect(() => {
    if (roleData && formRef?.current?.values) {
      formRef?.current?.setValues({
        role_name: roleData.data?.data?.display_name || '',
        description: roleData.data?.data?.description || '',
        rights: roleData.data?.data?.permissions?.map((permission) => permission.permissions._id),
      });
    }
  }, [roleData]);

  return { roleData, isLoadingRole, refetchRoleList };
};

export const useCreateRole = () => {
  const navigate = useNavigate();
  const handleCreateMutate = useMutation({
    mutationFn: (data) => AddRoleService(data),
  });
  const handleSubmitAddRoleForm = (values) => {
    const rights = [];
    for (let i = 0; i < values.rights.length; i++) {
      rights.push(values.rights[i]);
    }
    const postData = {
      name: values.role_name,
      description: values.description,
      permissions: rights,
    };
    handleCreateMutate.mutate(postData, {
      onSuccess: (res) => {
        SuccessToast(res?.data?.message || 'Role added successfully.');
        navigate('/admin/roles');
      },
      onError: (err) => {
        if (typeof err.response.data.message !== 'undefined') {
          ErrorToast(err.response.data.message || 'Server Error!!');
        } else {
          ErrorToast(err?.message || 'Server Error!!');
        }
      },
    });
  };
  return { handleSubmitAddRoleForm };
};

export const useUpdateRole = () => {
  const navigate = useNavigate();
  const stateData = useLocation();
  const { id } = stateData?.state || {};

  const handleUpdateMutate = useMutation({
    mutationFn: (data) => EditRoleService(data),
  });

  const handleSubmitEditRoleForm = (values) => {
    const rights = [];
    for (let i = 0; i < values.rights.length; i++) {
      rights.push(values.rights[i]);
    }
    const putData = {
      id,
      name: values.role_name,
      description: values.description,
      permissions: rights,
    };
    handleUpdateMutate.mutate(putData, {
      onSuccess: (res) => {
        SuccessToast(res?.data?.message || 'Role updated successfully.');
        navigate('/admin/roles');
      },
      onError: (err) => {
        if (typeof err.response.data.message !== 'undefined') {
          ErrorToast(err.response.data.message || 'Server Error!!');
        } else {
          ErrorToast(err?.message || 'Server Error!!');
        }
      },
    });
  };

  return { handleSubmitEditRoleForm };
};
