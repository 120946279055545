import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../../Utils';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import format from 'date-fns/format';
import { useChangeStatusUser, useDeleteUser, useGetUserPagination } from '../../hooks/useUserManagement';
import HelmetComponent from '../../../../Components/HelmetComponent';
import BreadCrumb from '../../../../Components/BreadCrumb';
import { useBreadCrumbData } from './hooks/useUserList';
import { useGetPermissions } from '../../../../Hooks/useGeneralHook';

const UserList = () => {
  const { permission_check } = useGetPermissions();
  const navigate = useNavigate();
  const { breadCrumbData } = useBreadCrumbData();
  const { usersList, isLoadingUserList, refetchUserList, page, limit, handlePageChange, handleLimitChange } =
    useGetUserPagination();
  const { handleDelete } = useDeleteUser(refetchUserList);
  const { handleChangeStatus } = useChangeStatusUser(refetchUserList);

  return (
    <React.Fragment>
      <HelmetComponent title='User List' />
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <BreadCrumb data={breadCrumbData} />
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive custom-pagination'>
                    <table className='table table-center table<img<image-hover datatable table-manager'>
                      <thead className='thead-light'>
                        <tr>
                          <th>Customer</th>
                          <th>Status</th>
                          <th className='text-center'>Action</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Joining Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingUserList ? (
                          <tr>
                            <td align='center' colSpan={6}>
                              Loading...
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment>
                            {usersList?.data?.data?.length > 0 ? (
                              <React.Fragment>
                                {usersList?.data?.data.map((user, index) => (
                                  <tr key={index}>
                                    <td>
                                      <h2 className='table-avatar text-white'>
                                        <img
                                          className='avatar-img rounded-circle'
                                          src={
                                            user?.profile_picture || '../inner-assets/img/profiles/default_image.jpg'
                                          }
                                          alt='User'
                                        />
                                        {user?.first_name || ''} {user?.last_name || ''}
                                      </h2>
                                    </td>
                                    <td>
                                      {permission_check('user-edit') ? (
                                        <React.Fragment>
                                          {user?.is_active === false && (
                                            <button
                                              className='btn btn-sm  btn-block btn-outline-danger'
                                              onClick={() => {
                                                handleChangeStatus(user, 'Want to active user?');
                                              }}
                                            >
                                              Inactive
                                            </button>
                                          )}
                                          {user?.is_active === true && (
                                            <button
                                              className='btn btn-sm  btn-block btn-outline-success'
                                              onClick={() => {
                                                handleChangeStatus(user, 'Want to deactivate user?');
                                              }}
                                            >
                                              Active
                                            </button>
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td className='text-center'>
                                      {permission_check('user-edit') && (
                                        <Tooltip
                                          title='Edit'
                                          className='btn btn-sm btn-white text-success me-2'
                                          onClick={() =>
                                            navigate('/admin/edit-user', {
                                              state: { id: user?._id },
                                            })
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon className='text-secondary' />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {permission_check('user-delete') && (
                                        <Tooltip
                                          title='Delete'
                                          className='btn btn-sm btn-white text-danger me-2'
                                          onClick={() => handleDelete(user?._id)}
                                        >
                                          <IconButton>
                                            <DeleteIcon className='text-danger' />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </td>
                                    <td>{user?.email || '-'}</td>
                                    <td>
                                      {user?.roles?.length > 0 ? (
                                        <React.Fragment>
                                          {user?.roles.map((role, role_index) => (
                                            <p className='m-0' key={`role-list-${role_index}`}>
                                              {role_index + 1}. {role?.role?.display_name || '-'}
                                            </p>
                                          ))}
                                        </React.Fragment>
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>{format(new Date(user?.createdAt), 'dd MMMM, yyyy')}</td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            ) : (
                              <tr>
                                <td colSpan={6}>No Records Found.</td>
                              </tr>
                            )}
                          </React.Fragment>
                        )}
                        <Pagination
                          totalItem={usersList?.data?.totalRecords || 0}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserList;
