import React, { memo } from 'react';
import Barcode from 'react-barcode';

// eslint-disable-next-line react/display-name
const BarcodeComponent = React.forwardRef(({ data }, ref) => {
  return (
    <div ref={ref}>
      <Barcode renderer="img" width={2} fontSize={15} height={80} format='UPC' value={data} />
    </div>
  );
});

export default memo(BarcodeComponent);
