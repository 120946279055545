/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import './style.css';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Admin Dashboard</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='row'>
            <div className='col-xl-4 col-sm-4 col-12'>
              <Link to='/admin/user-listing' className='dashboard-card-text-link'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='dash-widget-header'>
                      <span className='dash-widget-icon bg-pareto-theme-black'>
                        <i className='fas fa-users' />
                      </span>
                      <div className='dash-count'>
                        <div className='dash-title dashboard-card-text-link'>Users</div>
                        <div className='dash-counts input-label'>
                          <td className='h4'>0</td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
