import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AdminLayout } from '../Components/Layout';
import PrivateRoute from './PrivateRoute';
import { useEffect } from 'react';
import { ChangePassword, Settings } from '../Components/Admin/settings';
import Dashboard from '../Pages/Dashboard';
import Profile from '../Pages/Profile';
import { AddUser, EditUser, UserList } from '../Pages/UserManagement';
import { AddRole, EditRole, RoleList } from '../Pages/RoleManagement';
import PermissionAlert from '../Pages/PermissionAlert';
import { AddPermission, PermissionList } from '../Pages/PermissionManagement';
import { CustomerReturnsList } from '../Pages/CustomerReturnsManagement';
import { useGetPermissions } from '../Hooks/useGeneralHook';

const Logout = () => {
  const navigation = useNavigate();
  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('adminPermission');
    navigation('/');
  }, []);
};
const AdminRoutesPermission = () => {
  const location = useLocation();
  const { permission_check } = useGetPermissions();
  const route = location.pathname.slice(7);

  return (
    <React.Fragment>
      <React.Fragment>
        <AdminLayout />
        <Routes>
          <Route exact path='/' element={<PrivateRoute Component={Dashboard} />} />
          <Route exact path='/dashboard' element={<PrivateRoute Component={Dashboard} />} />

          {permission_check('user-list') && route === 'users' ? (
            <Route exact path='/users' element={<PrivateRoute Component={UserList} />} />
          ) : (
            <Route exact path='/users' element={<PermissionAlert />} />
          )}

          {permission_check('user-add') && route === 'add-user' ? (
            <Route exact path='/add-user' element={<PrivateRoute Component={AddUser} />} />
          ) : (
            <Route exact path='/add-user' element={<PermissionAlert />} />
          )}

          {permission_check('user-edit') && route === 'edit-user' ? (
            <Route exact path='/edit-user' element={<PrivateRoute Component={EditUser} />} />
          ) : (
            <Route exact path='/edit-user' element={<PermissionAlert />} />
          )}

          {permission_check('role-list') && route === 'roles' ? (
            <Route exact path='/roles' element={<PrivateRoute Component={RoleList} />} />
          ) : (
            <Route exact path='/roles' element={<PermissionAlert />} />
          )}

          {permission_check('role-add') && route === 'add-role-management' ? (
            <Route exact path='/add-role-management' element={<PrivateRoute Component={AddRole} />} />
          ) : (
            <Route exact path='/add-role-management' element={<PermissionAlert />} />
          )}

          {permission_check('role-edit') && route === 'edit-role-permission' ? (
            <Route exact path='/edit-role-permission' element={<PrivateRoute Component={EditRole} />} />
          ) : (
            <Route exact path='/edit-role-permission' element={<PermissionAlert />} />
          )}

          {permission_check('permission-list') && route === 'permissions' ? (
            <Route exact path='/permissions' element={<PrivateRoute Component={PermissionList} />} />
          ) : (
            <Route exact path='/permissions' element={<PermissionAlert />} />
          )}

          {permission_check('permission-add') && route === 'add-permission-management' ? (
            <Route exact path='/add-permission-management' element={<PrivateRoute Component={AddPermission} />} />
          ) : (
            <Route exact path='/add-permission-management' element={<PermissionAlert />} />
          )}

          <Route exact path='/profile' element={<PrivateRoute Component={Profile} />} />

          {permission_check('customer-returns') && route === 'customer-returns' ? (
            <Route exact path='/customer-returns' element={<PrivateRoute Component={CustomerReturnsList} />} />
          ) : (
            <Route exact path='/customer-returns' element={<PermissionAlert />} />
          )}

          <Route exact path='/setting' element={<PrivateRoute Component={Settings} />} />
          <Route exact path='/setting/change-password' element={<PrivateRoute Component={ChangePassword} />} />
          <Route exact path='/logout' element={<Logout />} />
        </Routes>
      </React.Fragment>
    </React.Fragment>
  );
};
export default AdminRoutesPermission;
