import Api from '../Utils/Axios';

export const GetRoleListService = async () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get('/role'));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetResourceService = async () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get('/resource'));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetPermissionsService = async () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get('/permission/all-permissions'));
    } catch (error) {
      reject(error);
    }
  });
};

export const AddRoleService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/role', data));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditRoleService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.put('/role', data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetRoleListById = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get(`/role/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteRoleById = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.delete('/role', { data: { id: id } }));
    } catch (error) {
      reject(error);
    }
  });
};
