import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Helmet } from 'react-helmet-async';
import { SuccessToast, ErrorToast } from '../../../Utils/SweetAlert'; //ErrorToast
import { useMutateLoginHook } from './hooks/useApiHook';
import { LoginInitialValues, LoginValidationSchema } from './constant';
import './style.css';
import InputWithLabel from '../../../Components/InputWithLabel';
import PassowrdField from '../../../Components/PasswordField';

const Login = () => {
  // const navigate = useNavigate();
  const formRef = useRef();
  const loginPost = useMutateLoginHook();

  const handleSubmitLoginForm = (values) => {
    loginPost.mutate(values, {
      onSuccess: (data) => {
        if (data.status === 1) {
          SuccessToast(data?.message || 'Login successful.');
          if (formRef.current) {
            formRef.current.setSubmitting(false);
          }
          localStorage.setItem('accessToken', data?.data?.token);
          window.location.href = '/admin/dashboard';
          // navigate('/admin/dashboard');
        } else {
          ErrorToast(data?.message || 'Server error.');
        }
      },
      onError: (error) => {
        ErrorToast(error?.response?.data?.message || 'Server error.');
        if (formRef.current) {
          formRef.current.setSubmitting(false);
        }
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Admin Login</title>
      </Helmet>
      <div>
        <div className='main-wrapper login-body'>
          <div className='login-wrapper'>
            <div className='container'>
              <h1 className='img-fluid text-center text-white'>{process.env.REACT_APP_NAME} Admin</h1>
              <div className='loginbox'>
                <div className='login-right'>
                  <div className='login-right-wrap'>
                    <h1 className='login-title'>Login</h1>
                    {/* <p className='login-subtitle'>Access to our dashboard</p> */}
                    <Formik
                      initialValues={LoginInitialValues}
                      validationSchema={LoginValidationSchema}
                      innerRef={formRef}
                      validateOnChange
                      onSubmit={handleSubmitLoginForm}
                    >
                      {({ isValid, handleSubmit, isSubmitting }) => {
                        return (
                          <Form onSubmit={handleSubmit}>
                            <Field
                              Label='Email'
                              type='email'
                              name='email'
                              placeholder='Enter email'
                              component={InputWithLabel}
                            />
                            <div className='form-group'>
                              <label className='text-white'>
                                Password <span className='text-danger'>*</span>
                              </label>
                              <Field component={PassowrdField} name='password' placeholder='Enter password' />
                              <ErrorMessage name='password' component='div' className='text-danger' />
                            </div>
                            <div className='form-group'>
                              <div className='row'>
                                <div className='col-6'>
                                  <div className='custom-control custom-checkbox'>
                                    <label className='custom_check' htmlFor='cb1'>
                                      <input type='checkbox' name='invoice' id='cb1' />
                                      Remember me
                                      <span className='checkmark' />
                                    </label>
                                  </div>
                                </div>
                                <div className='col-6 text-end'>
                                  <NavLink to='/forgot-password'>Forgot Password ?</NavLink>
                                </div>
                              </div>
                            </div>
                            <button
                              type='submit'
                              className={`login-submit-btn btn btn-lg btn-block w-100 ${
                                !isValid ? 'disabled-btn' : ''
                              }`}
                              disabled={isSubmitting}
                            >
                              Login&nbsp;&nbsp;
                              {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                            </button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
