import Api from '../Utils/Axios';
import axios from 'axios';
import { Buffer } from 'buffer';

export const fileUploadToBucket = async ({ data, file }) => {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      var fileBuf = '';
      reader.onload = function () {
        let base64 = reader.result;
        if (data.mime_type === 'application/pdf') {
          fileBuf = Buffer.from(base64.replace(/^data:application\/\w+;base64,/, ''), 'base64');
        } else if (data.mime_type === 'application/msword') {
          fileBuf = Buffer.from(base64.replace('data:application/msword;base64,', ''), 'base64');
        } else if (data.mime_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          fileBuf = Buffer.from(
            base64.replace('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,', ''),
            'base64',
          );
        } else if (data.mime_type === 'application/vnd.ms-powerpoint') {
          fileBuf = Buffer.from(base64.replace('data:application/vnd.ms-powerpoint;base64,', ''), 'base64');
        } else if (data.mime_type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
          fileBuf = Buffer.from(
            base64.replace(
              'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,',
              '',
            ),
            'base64',
          );
        } else {
          fileBuf = Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        }
      };
      reader.onerror = function (error) {
        reject(error);
      };
      getUploadUrl(data).then(async (urlRes) => {
        const instance = axios.create();
        instance({
          method: 'put',
          url: urlRes.data.data.url, // AWS pre-signed url
          data: fileBuf,
          headers: {
            'Content-Type': file.type,
          },
        })
          .then(async () => {
            resolve(await uploadFile({ file_id: urlRes.data.data.file_id }));
          })
          .catch((err) => {
            reject(err);
          });
      });
    } catch (error) {
      reject(error);
    }
  });
};
const getUploadUrl = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/master/file/getUploadUrl', data));
    } catch (error) {
      reject(error);
    }
  });
};
const uploadFile = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/master/file/upload', data));
    } catch (error) {
      reject(error);
    }
  });
};
export const getUploadedList = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get(`/master/file/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};
