import React from 'react';

export const breadCrumbData = {
  title: 'Add User',
  bread_crumbs: [
    {
      class: 'breadcrumb-item',
      label: 'Dashboard',
      path: '/admin/dashboard',
    },
    {
      class: 'breadcrumb-item',
      label: 'Users',
      path: '/admin/users',
    },
    {
      class: 'breadcrumb-item active',
      label: 'Add User',
    },
  ],
  header_action: {
    path: '/admin/users',
    children: (
      <React.Fragment>
        <i className='fas fa-angle-double-left' /> Back
      </React.Fragment>
    ),
  },
};
