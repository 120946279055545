import React from 'react';
import { Pagination } from '../../../../Utils';
import format from 'date-fns/format';
// import { AddPermission } from '../..';
import { useGetPermissions } from '../../hooks/usePermissionManagement';
import HelmetComponent from '../../../../Components/HelmetComponent';
import BreadCrumb from '../../../../Components/BreadCrumb';
import { breadCrumbData } from './constant';

const PermissionList = () => {
  const { page, limit, handlePageChange, handleLimitChange, isLoadingPermissions, permissionList } =
    useGetPermissions();

  return (
    <React.Fragment>
      <HelmetComponent title='Admin Permissions Management' />
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <BreadCrumb data={breadCrumbData} />
          {/* <AddPermission refetchPermission={refetchPermission} /> */}
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive custom-pagination'>
                    <table className='table table-center table<img<image-hover datatable table-manager'>
                      <thead className='thead-light'>
                        <tr>
                          <th>Name</th>
                          <th>Slug</th>
                          <th>Create At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingPermissions ? (
                          <tr>
                            <td align='center' colSpan={3}>
                              Loading...
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment>
                            {permissionList?.data?.data?.length > 0 ? (
                              <React.Fragment>
                                {permissionList?.data?.data.map((permission, index) => (
                                  <tr key={`permission-list-tr-${index}`}>
                                    <td>
                                      <h2 className='table-avatar text-white'>{permission?.display_name || ''}</h2>
                                    </td>
                                    <td>
                                      <h2 className='table-avatar text-white'>{permission?.name || ''}</h2>
                                    </td>
                                    <td>{format(new Date(permission?.createdAt), 'dd MMMM, yyyy')}</td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            ) : (
                              <tr>
                                <td colSpan={3}>No Records Found.</td>
                              </tr>
                            )}
                          </React.Fragment>
                        )}
                        <Pagination
                          totalItem={permissionList?.data?.totalRecords || 0}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={3}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PermissionList;
