import Api from '../Utils/Axios';

export const AddUserService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/users', data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetUserPaginate = async ({ limit, page }) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get(`/users?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteUserById = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.delete('/users', { data: { id } }));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveUser = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/users/status', data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetUserByIdService = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get(`/users/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditUserService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.put(`/users`, data));
    } catch (error) {
      reject(error);
    }
  });
};

export const adminGetUserPermissionService = async () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get('/users/get-user-permissions'));
    } catch (error) {
      reject(error);
    }
  });
};
