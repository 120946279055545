import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import './style.css';

const InputWithLabel = ({ field, type, placeholder, Label, disabled = false }) => {
  const { handleChange } = useFormikContext();

  return (
    <div className='form-group'>
      <label className='text-white'>
        {Label} <span className='text-danger'>*</span>
      </label>
      <input
        className='input-customize'
        {...field}
        type={type}
        value={field.value}
        onChange={handleChange}
        name={field.name}
        placeholder={placeholder}
        disabled={disabled}
      />
      <ErrorMessage name={field.name} component='div' className='text-danger' />
    </div>
  );
};

export default InputWithLabel;
