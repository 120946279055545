import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { ForgotPasswordService } from '../../../Api/auth';
import { SuccessToast, ErrorToast } from '../../../Utils/SweetAlert';
import { Helmet } from 'react-helmet-async';
import { ForgotPasswordInitialValues, ForgotPasswordValidationSchema } from './constant';
import './style.css';
import InputWithLabel from '../../../Components/InputWithLabel';

const ForgotPassword = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Admin Forgot Password</title>
      </Helmet>
      <div>
        <div className='main-wrapper login-body'>
          <div className='login-wrapper'>
            <div className='container'>
              <h1 className='img-fluid text-center text-white'>{process.env.REACT_APP_NAME} Admin</h1>
              <div className='loginbox'>
                <div className='login-right'>
                  <div className='login-right-wrap'>
                    <h1 className='forgot-title'>Forgot Password</h1>
                    <p className='forgot-subtitle'>Enter your email to get a password reset link</p>
                    <Formik
                      initialValues={ForgotPasswordInitialValues}
                      validationSchema={ForgotPasswordValidationSchema}
                      validateOnChange
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        await ForgotPasswordService({ ...values })
                          .then((res) => {
                            SuccessToast(res?.data?.message);
                            setSubmitting(false);
                            resetForm();
                            navigate('/');
                          })
                          .catch((err) => {
                            if (typeof err.response.data.message !== 'undefined') {
                              ErrorToast(err.response.data.message || 'Server Error!!');
                            } else {
                              ErrorToast(err?.message || 'Server Error!!');
                            }
                            setSubmitting(false);
                          });
                      }}
                    >
                      {({ handleSubmit, isSubmitting }) => {
                        return (
                          <Form onSubmit={handleSubmit}>
                            <Field
                              Label='Email'
                              type='email'
                              name='email'
                              placeholder='Enter email'
                              component={InputWithLabel}
                            />
                            <div className='form-group mb-0'>
                              <button
                                className='forgot-submit-btn btn btn-lg btn-block btn-primary w-100'
                                disabled={isSubmitting}
                                type='submit'
                              >
                                Reset Password&nbsp;&nbsp;
                                {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                    <div className='text-center remember-text-class-link mt-4'>
                      Remember your password?{' '}
                      <NavLink to='/' className=''>
                        Login
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
